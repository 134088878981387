import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Brand, Provider, ProviderItem, Tax } from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, filter, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface ProviderItemState {
    providerItems: ProviderItem[];
    count: number | null;
    query: { skip: number; take: number; search?: string; provider?: string };
    providers: Provider[];
    taxes: Tax[];
    brands: Brand[];
}

@Injectable()
export class ProviderItemStore extends ComponentStore<ProviderItemState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super({
            providerItems: [],
            count: null,
            query: { skip: 0, take: 20 },
            providers: [],
            taxes: [],
            brands: [],
        });
    }

    readonly getProviderItems = this.select((state) => state.providerItems);
    readonly getCount = this.select((state) => state.count);
    readonly getQuery = this.select((state) => state.query);
    readonly getProviders = this.select((state) => state.providers);
    readonly getTaxes = this.select((state) => state.taxes);
    readonly getBrands = this.select((state) => state.brands);

    readonly updateQuery = this.updater((state, payload: any) => {
        const query = { ...state.query, ...payload };
        if (!query.search || query.search.trim() === '') delete query.search;
        if (!query.provider || query.provider.trim() === '') delete query.provider;
        this.router.navigate([], { relativeTo: this.route, queryParams: query });

        return { ...state, query };
    });

    readonly getProviderItemsEffect = this.effect((params$: Observable<any>) =>
        params$.pipe(
            filter((params) => !!params && Object.keys(params)?.length !== 0),
            switchMap((params) => {
                let decodedParams = new HttpParams();
                for (const [queryKey, queryVal] of Object.entries(params)) {
                    if (typeof queryVal === 'string') {
                        decodedParams = decodedParams.append(queryKey, queryVal);
                    } else {
                        decodedParams = decodedParams.append(queryKey, JSON.stringify(queryVal));
                    }
                }

                return this.http.get<{ count: number; items: ProviderItem[] }>('/api/providers/items', { params: decodedParams }).pipe(
                    tap((countAndItems) => this.patchState({ providerItems: countAndItems.items, count: countAndItems.count })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getProviderNamesEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Provider[]>('/api/providers', { params: { select: 'name' } }).pipe(
                    tap((providers) => {
                        this.patchState({ providers });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getTaxesEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Tax[]>('/api/products/taxes').pipe(
                    tap((taxes) => {
                        this.patchState({ taxes });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getBrandsEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Brand[]>('/api/brands').pipe(
                    tap((brands) => {
                        this.patchState({ brands });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
}
